const getUserFieldsFragment = `
  agreedToMarketing
  agreedToTerms
  applications {
    items {
      id
      isNotActive
      createdAt
      jobType {
        id
        title
      }
      preferredRate {
        currency
        value
      }
    }
  }
  availability
  badges {
    count
    description
    entityId
    image
    issuedOn
    name
  }
  bio
  company
  companyDetails {
    address {
      city
      country
      line1
      line2
      postalCode
      state
    }
    billingEmail
    bio
    logo
    name
    tagline
    timezone {
      label
      value
    }
    url
  }
  countryName
  coverPhoto
  createdAt
  creator
  email
  family_name
  githubAccessToken
  githubRefreshToken
  nextAvailableDate
  lastActivityDate
  given_name
  headshotKey
  id
  identity_username
  jobOpportunities {
    items {
      id
      title
      status
      jobType {
        id
        title
      }
      customer {
        username
      }
      matches {
        items {
          applicationId
          createdAt
          creator
          jobOpportunityId
          status
        }
      }
    }
  }
  jobRole
  knownLanguages {
    language
    level
  }
  location {
    cityName
    countryCode
    countryId
    countryName
    latitude
    locationId
    longitude
    stateCode
    stateId
    stateName
    wikiDataId
  }
  matches {
    items {
      applicationId
      createdAt
      creator
      jobOpportunityId
      jobOpportunity {
        title
      }
      status
    }
  }
  milestoneRewards {
    id
    name
  }
  profileCompletion
  ratePerHour {
    currency
    value
  }
  referralCode
  referralCount
  referrerCode
  shirtSize
  skills {
    id
    infoUrl
    name
    type {
      id
      name
    }
  }
  socialLinks {
    type
    value
  }
  status
  systemModTimestamp
  systemProcessName
  tagline
  updatedAt
  updater
  userType
  username
  visibility
  otherLinks {
    createdAt
    creator
    description
    name
    type
    updatedAt
    updater
    value
    visibility
  }
  phone {
    number
    whatsAppAllowed
  }
  talentSource
  additionalMarkup
  metadata
`;

export const getUserQuery = `
  query GetUser($id: ID!) {
    fields: getUser(id: $id) {
      ${getUserFieldsFragment}
    }
  }
`;

export const getUserByUsernameQuery = `
  query GetUserByUsername($username: String!) {
    fields: getUserByUsername(username: $username) {
      items {
        ${getUserFieldsFragment}
      }
    }
  }
`;

export const getUserByIdentityUsername = `
  query GetUserByIdentityUsername($identity_username: String!) {
    fields: getUserByIdentityUsername(identity_username: $identity_username) {
      items {
        ${getUserFieldsFragment}
      }
    }
  }
`;

export const getUserByEmailQuery = `
  query GetUserByEmail($email: AWSEmail!) {
    fields: getUserByEmail(email: $email) {
      items {
        ${getUserFieldsFragment}
      }
    }
  }
`;

export const getJobRoleQuery = `
  query GetJobType($id: ID!) {
    fields: getJobType(id: $id) {
      applications(limit: 100) {
        items {
          createdAt
          creator
          id
        }
      }
      jobOpportunities {
        items {
          title
          id
          status
          customer {
            username
          }
        }
      }
      createdAt
      creator
      id
      isActive
      overview
      requirements
      responsibilities
      skills {
        type {
          id
          name
        }
        infoUrl
        name
        id
      }
      title
      updatedAt
      updater
      commitment
      lowEndLength
      highEndLength
      metadata
    }
  }
`;

export const getApplicationQuery = `
  query GetApplication($id: ID!) {
    fields: getApplication(id: $id) {
      availableStartDate
      createdAt
      creator 
      id
      jobTypeId
      jobType {
        title
      }
      preferredRate {
        currency
        value
      }
      isNotActive
      systemModTimestamp
      systemProcessName
      updatedAt
      updater
      userId
      user {
        identity_username
        username
      }
      metadata
    }
  }
`;

export const getJobOpportunityQuery = `
  query GetJobOpportunity($id: ID!) {
    fields: getJobOpportunity(id: $id) {
      createdAt
      creator
      customer {
        username
      }
      torcOwner {
        id
        username
      }
      customerId
      id
      jobLength
      jobLengthInWeeks
      jobType {
        title
      }
      jobTypeId
      maxRate {
        currency
        value
      }
      minRate {
        currency
        value
      }
      organization
      overview
      regions
      requirements
      responsibilities
      shortDescription
      skills {
        id
        infoUrl
        name
        type {
          id
          name
        }
      }
      openPositions
      optionalSkills {
        id
        infoUrl
        name
        type {
          id
          name
        }
      }
      priorityLevel
      requiredPositions
      startDate
      status
      visibilityLevel
      systemModTimestamp
      systemProcessName
      timeCommitment
      timeOverlap
      timezone {
        label
        value
      }
      title
      updatedAt
      updater
      matches {
        items {
          applicationId
          createdAt
          creator
          jobOpportunityId
          status
          user {
            identity_username
            username
          }
          userId
        }
      }
      metadata
    }
  }
`;

export const searchSkillsQuery = `
  query SearchSkills($term: String!, $limit: Int = 10) {
    searchSkills(term: $term, limit: $limit) {
      id
      infoUrl
      name
      type {
        id
        name
      }
    }
  }
`;

export const getUserBadgesQuery = `
  query GetProfileByUsername($username: String!) {
    getUserBadges: getProfileByUsername(username: $username) {
      badges {
        count
        description
        entityId
        image
        issuedOn
        name
      }
    }
  }
`;

export const getAllBadgesQuery = `
  query GetBadgesFromIssuer {
    getBadgesFromIssuer {
      name
      entityId
      description
      image
    }
  }
`;

export const listJobRolesQuery = `
  query ListJobTypes {
    listJobTypes {
      items {
        id
        overview
        requirements
        lowEndLength
        highEndLength
        commitment
        responsibilities
        skills {
          name
        }
        title
        applicationCount
        isActive
      }
    }
  }
`;

export const listJobRolesForSelectQuery = `
  query ListJobTypes {
    listJobTypes {
      items {
        id
        title
        isActive
      }
    }
  }
`;

export const listJobOpportunitiesQuery = `
  query ListJobOpportunitys {
    listJobOpportunitys(limit: 20) {
      items {
        id
        title
        status
        customer {
          username
        }
      }
    }
  }
`;

export const getMatchQuery = `
  query GetMatch($applicationId: ID!, $jobOpportunityId: ID!) {
    fields: getMatch(applicationId: $applicationId, jobOpportunityId: $jobOpportunityId) {
      applicationId
      availableStartDate
      createdAt
      creator
      freelancerPitch
      startDate
      endDate
      customerRate {
        currency
        value
      }
      disableNotification
      isAccepted
      jobOpportunityId
      moreInfoRequest
      moreInfoRequestMessage
      rate {
        currency
        value
      }
      reasonForRejection
      rejectionByCustomer
      status
      systemModTimestamp
      systemProcessName
      updatedAt
      updater
      jobOpportunity {
        title
      }
      user {
        applications {
          items {
            id
            jobType {
              title
            }
          }
        }
        identity_username
        username
      }
      userId
      metadata
    }
  }
`;

const listNotesFieldsFragment = `
  items {
    content
    createdAt
    creator
    id
    isPublic
    applicationId
  }
`;

export const listUserNotesQuery = `
  query ListUserNotes($userId: ID!) {
    listNotes: listUserNotes(userId: $userId) {
      ${listNotesFieldsFragment}
    }
  }
`;

export const listJobOpportunityNotesQuery = `
  query ListJobOpportunityNotes($jobOpportunityId: ID!) {
    listNotes: listJobOpportunityNotes(jobOpportunityId: $jobOpportunityId) {
      ${listNotesFieldsFragment}
    }
  }
`;

export const listMatchNotesQuery = `
  query ListMatchNotes($applicationId: ID!, $jobOpportunityId: ID!) {
    listNotes: listMatchNotes(applicationId: $applicationId, jobOpportunityId: {eq: $jobOpportunityId}) {
      ${listNotesFieldsFragment}
    }
  }
`;

export const getUserAssessmentsQuery = `
  query GetUser($id: ID!) {
    getUserAssessments: getUser(id: $id) {
      assessments {
        assessmentCompleted
        codeScore
        finalScore
        id
        multipleChoiceScore
        plagiarism
        reportLink
        status
        testId
        testName
        timeTaken
      }
    }
  }
`;

export const listCognitoGroups = /* GraphQL */ `
  query ListCognitoGroups {
    listCognitoGroups {
      groups
    }
  }
`;

export const getCognitoGroupsForUser = /* GraphQL */ `
  query GetCognitoGroupsForUser($username: String!) {
    getCognitoGroupsForUser(username: $username) {
      groups
    }
  }
`;

export const generateJobShortDescriptionQuery = /* GraphQL */ `
  query GenerateJobShortDescription(
    $jobDetails: String!
    $wordLimit: Int = 200
  ) {
    generateJobShortDescription(
      jobDetails: $jobDetails
      wordLimit: $wordLimit
    ) {
      shortDescription
    }
  }
`;
